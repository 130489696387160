import axios from "axios";
import { 
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_LOGIN_ERROR, 
} from "../types";
import setAuthToken from "../../utils/setAuthToken";
import config from "../../config";
import displayMSG from "../../utils/displayMSG";

export const loadUser = () => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const response = await axios.get(`${config.BACKEND_URL}/user/profile`);
        if (response.data.res.status === 200) {
            dispatch({
                type: USER_LOADED,
                payload: response.data.res.data,
            });
        } else {
            dispatch({
                type: AUTH_ERROR,
            });
        }
    } catch (err) {
        console.log("LOAD-USER-ERROR");
        dispatch({
            type: AUTH_ERROR,
        });
    }
};


export const login = (username, password) => async (dispatch) => {
    const headerConfig = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const body = JSON.stringify({ email: username, password });

    try{
        dispatch({
            type: SET_LOGIN_ERROR,
            payload: null,
        });
        const response = await axios.post(`${config.BACKEND_URL}/user/login`, body, headerConfig);
        
        if (response.data.res.status === 200) {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { data: response.data.res.data },
            });
            localStorage.setItem("token", response.data.res.authToken);
            localStorage.setItem("username", username);

            dispatch(loadUser());
        } else {
            const error = response.data;
            dispatch({
                type: SET_LOGIN_ERROR,
                payload: error,
            });
            dispatch({
                type: LOGIN_FAIL,
                payload: error,
            });

        }

        if (response?.data?.res?.status === 401) {
            dispatch({
                type: AUTH_ERROR,
            });
        }

    } catch (err) {
        console.log("LOGIN-ERROR");
        const error = err.response.data;

        dispatch({
            type: SET_LOGIN_ERROR,
            payload: error,
        });
        dispatch({
            type: LOGIN_FAIL,
            payload: error,
        });
    }
};


export const logout = () => async (dispatch) => {
    dispatch({
        type: LOGOUT,
    });

    try{
        const response = await axios.get(`${config.BACKEND_URL}/logout`);
        displayMSG('success', response.data.res.message);
    } catch (err) {
        console.log("LOGOUT-ERROR");
        //error to display
        dispatch({
            type: "error",
            message: err?.message,
        });
    }
};

